.fallback {
  position: relative;
  min-height: var(--mobile);

  @media only screen and (min-width: 768px) {
      min-height: var(--tablet);
  }

  @media only screen and (min-width: 1200px) {
    min-height: var(--desktop);
  }
}

/* -----------------------------------------------------------------------------
 *
 * Components: Return Visitor Modal
 *
 * ----------------------------------------------------------------------------- */

 @import '../../../../styles/utilities/common.css';

 .component {
   padding: 0;
   text-align: center;
 }
 
 /* -----------------------------------------------------------------------------
  * Modal Container
  * ----------------------------------------------------------------------------- */
 
 .component__modalContainer {
   max-width: calc( var( --grid-width-default ) - (var( --grid-gutter ) * 2) );
   margin-top: 60px;
   position: relative;
   padding: 0;
 
   @media (--medium) {
     max-width: var( --grid-width-default );
   }
 
   @media (--large) {
     margin-top: 0;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Content Wrapper
  * ----------------------------------------------------------------------------- */
 
 .component__contentWrapper {
   padding: 40px 24px;
 
   @media (--medium) {
     padding: 80px 45px;
   }
 
   @media (--xlarge) {
     padding: 80px 100px;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Header
  * ----------------------------------------------------------------------------- */
 
 .component__header {
   display: block;
   margin-bottom: 32px;
 
   @media (--medium) {
     margin-bottom: 56px;
   }
 
   @media (--large) {
     margin-bottom: 70px;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Close
  * ----------------------------------------------------------------------------- */
 
 .component__close {
   position: absolute;
   right: var( --grid-gutter-half );
   font-size: rem( 21px );
   margin-top: 5px;
 }
 
 /* -----------------------------------------------------------------------------
  * Title
  * ----------------------------------------------------------------------------- */
 
 .component__title {
   margin-bottom: 24px;
   padding: 0;
   color: var(--color-torch-red);
 
   @media (--medium) {
     margin-bottom: 24px;
   }
 
   @media (--large) {
     margin-bottom: 32px;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Subtitle
  * ----------------------------------------------------------------------------- */
 
 .component__subtitle {
   margin-bottom: 0;
   font-size: rem( 18px );
   font-weight: 700;
   line-height: lh( 18px, 22px );
 
   @media (--medium) {
     margin: 0 auto;
   }
 
   @media (--large) {
     max-width: 70%;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Body
  * ----------------------------------------------------------------------------- */
 
 .component__body {
   margin: 0;
 }
 
 /* -----------------------------------------------------------------------------
  * Blocks
  * ----------------------------------------------------------------------------- */
 
 .component__blocks {
   display: flex;
   flex-flow: column;
   align-items: flex-start;
   justify-content: center;
 
   @media (--medium) {
     flex-flow: row;
     align-items: stretch;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Block
  * ----------------------------------------------------------------------------- */
 
 .component__block {
   display: flex;
   flex-flow: column;
   margin-bottom: 32px;
   color: var( --color-midnight-express );
 
 
   @media (--medium) {
     display: block;
     flex: 1;
     margin-right: 24px;
     margin-bottom: 0;
   }
 
   @media (--large) {
     margin-right: 80px;
   }
 }
 
 .component__blocks .component__block:last-of-type {
   margin-bottom: 0;
 
   @media (--medium) {
     margin-right: 0;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Block Title
  * ----------------------------------------------------------------------------- */
 
 .component__blockTitle {
   padding: 0;
   margin-bottom: 16px;
 
   @media (--medium) {
     margin-bottom: 24px;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Block Content
  * ----------------------------------------------------------------------------- */
 
 .component__blockContent {
   margin-bottom: 16px;
 
   @media (--medium) {
     margin-bottom: 30px;
     min-height: 100px;
   }
 
   @media (--hd) {
     min-height: 85px;
   }
 }
 
 /* -----------------------------------------------------------------------------
  * Block Button
  * ----------------------------------------------------------------------------- */
 
 .component__blockBtn {
   display: block;
   font-size: rem( 14px );
   line-height: lh( 14px, 22px );
   margin: 0;
 
   @media (--large) {
     display: inline-block;
   }
 }
 
 a.component__blockBtn[ href^='tel' ] {
   color: var( --color-white );
 }
 
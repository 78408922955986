/* -----------------------------------------------------------------------------
 *
 * Components: CTA Banner
 *
 * ----------------------------------------------------------------------------- */

@import '../../../../styles/utilities/common.css';

.component {
  padding: 30px 0;

  @media (--medium) {
    padding: 25px 0;
  }
}

.component__body {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--large) {
    flex-direction: row;
    justify-content: center;
  }
}

.component__copy {
  margin-bottom: 20px;
  text-align: center;

  @media (--medium) {
    font-weight: 700;
  }

  @media (--large) {
    margin: 0 35px 0;
  }
}

.component__btn {
  @media (--medium) {
    width: auto;
  }
}

/* override */
.component__btn.component__phoneBtn {
  color: var( --color-white );
}

.component__modalContainer {
  width: var( --grid-width-default );
  padding: 0;
  border-radius: 24px;
}

.component__close {
  position: absolute;
  right: 0;
  margin: 26px 27px 0 0 ;
}

.component__close::before {
  font-size: 24px;
  font-weight: 700;
}

.component__contentWrapper {
  text-align: center;
  padding: 80px 0;

  @media (--medium-max) {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.component__title {
  margin: 0 0 16px 0;
  padding: 0;

  @media (--medium) {
    font-size: rem( 30px );
    line-height: lh( 30px, 34px );
  }
}

.component__subtitle {
  font-weight: 500;
}

.component__addressWrapper {
  margin: 42px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  @media (--medium-max) {
    flex-wrap: wrap;
    margin: 34px 0;
  }
}

.component__icon {
  font-size: 24px;
  margin-right: 8px;
}

.component__address {
  margin: 10px 0 0;
  text-transform: uppercase;

  @media (--medium) {
    margin: 0 0 0 8px;
  }
}

.component__buttonWrapper {
  width: 265px;
  margin: 0 auto;
}